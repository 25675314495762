import * as React from 'react';
import { AppRegistry, View, Pressable, Platform } from 'react-native';
import { Divider, DefaultTheme, Provider as PaperProvider, MD3DarkTheme as DarkTheme } from 'react-native-paper';
import LogRocket from 'logrocket';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from "@react-navigation/drawer";
import Ionicons from "@expo/vector-icons/Ionicons";
import { Appearance } from 'react-native';

const cameraHack = false;

import AISearch from './AISearch';
import History from './History';
import Profile from './Profile';
import About from './About';
import Advanced from './Advanced'
import Embeddings from './Embeddings';
import { AsyncStorageProvider, AsyncStorageContext } from './AsyncStorageContext';

//import Camera from './Camera';

import { theme } from './common';

const Drawer = createDrawerNavigator();

const defaultOptions =({})=>({
    //Show sandwith-menu icon at the right
    headerRight: () => {
          return (
            <>
              <Pressable onPress={() => navigation.openDrawer()}>
                <Ionicons
                  name={Platform.OS === "android" ? "md-menu" : "ios-menu"}
                  size={32}
                  color={"#000"}
                  style={{ marginRight: 10 }}
                />
              </Pressable>
            </>
          );
        },
     //Hide the left icon menu
     headerLeftContainerStyle: { display: "none" },
        headerRightContainerStyle: {
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row-reverse",
          alignItems: "center",
          marginRight: 10,
        },
        //Hide the left menu Title
        headerTitleStyle: {
          display: "none",
        },
        drawerPosition: "right",
})

function App() {
    const { getItem } = React.useContext(AsyncStorageContext);
    const [ advancedMode, setAdvancedMode ] = React.useState(false);

    React.useEffect(() => {
        LogRocket.init('gi7h8x/sparkbot-ai');

        getItem('username').then((value) => {
            console.log('username', value)
            if (value && (value.length > 0)) {
                LogRocket.identify(value)
            }
        });

        getItem('advancedMode').then((value) => {
            console.log('advancedMode', value)
            setAdvancedMode(value === 'true' ? true : false)
        });

        //print out the default color cheme
        const colorScheme = Appearance.getColorScheme();
        console.log('colorScheme', colorScheme)
    }, []);

    console.log('advancedMode', advancedMode)

    return (
        <NavigationContainer>
            <Drawer.Navigator>
                <Drawer.Screen
                    name="SparkbotAI"
                    options={{
                        title: "Sparkbot AI",
                            drawerIcon: ({ color, size, focused }) => (
                                <Ionicons
                                    name={focused ? "chatbox-ellipses-outline" : "chatbox-ellipses"}
                                    size={size}
                                    color={color}
                                />
                            ),
                    }}
                    initialParams={{ theme: theme }}
                    component={AISearch}
                />
                <Drawer.Screen
                    name="History"
                    options={{
                    title: "History",
                        drawerIcon: ({ color, size, focused }) => (
                            <Ionicons
                            name={
                                focused ? "play-back-outline" : "play-back"
                            }
                            size={size}
                            color={color}
                            />
                        ),
                    }}
                    initialParams={{ theme: theme }}
                    component={History}
                />
                { advancedMode &&
                    <Drawer.Screen
                        name="Embeddings"
                        options={{
                        title: "Embeddings Search",
                            drawerIcon: ({ color, size, focused }) => (
                                <Ionicons
                                    name={
                                        focused ? "search-outline" : "search"
                                    }
                                    size={size}
                                    color={color}
                                />
                            ),
                        }}
                        initialParams={{ theme: theme }}
                        component={Embeddings}
                    />
                }
                { advancedMode &&
                    <Drawer.Screen
                        name="Advanced"
                        options={{
                        title: "Advanced Settings",
                            drawerIcon: ({ color, size, focused }) => (
                                <Ionicons
                                    name={
                                        focused ? "md-settings-outline" : "md-settings"
                                    }
                                    size={size}
                                    color={color}
                                />
                            ),
                        }}
                        initialParams={{ theme: theme }}
                        component={Advanced}
                    />
                }
                { false && 
                    <Drawer.Screen
                        name="Camera"
                        options={{
                        title: "Camera",
                            drawerIcon: ({ color, size, focused }) => (
                                <Ionicons
                                    name={
                                        focused ? "help-circle-outline" : "help-circle"
                                    }
                                    size={size}
                                    color={color}
                                />
                            ),
                        }}
                        initialParams={{ theme: theme }}
                        component={Camera}
                    />
                }
                <Drawer.Screen
                    name="Profile"
                    options={{
                    title: "Profile",
                        drawerIcon: ({ color, size, focused }) => (
                            <Ionicons
                            name={
                                focused ? "person-circle-outline" : "person-circle"
                            }
                            size={size}
                            color={color}
                            />
                        ),
                    }}
                    initialParams={{ theme: theme }}
                    component={Profile}
                />
                <Drawer.Screen
                    name="About"
                    options={{
                    title: "About",
                        drawerIcon: ({ color, size, focused }) => (
                            <Ionicons
                                name={
                                    focused ? "help-circle-outline" : "help-circle"
                                }
                                size={size}
                                color={color}
                            />
                        ),
                    }}
                    initialParams={{ theme: theme }}
                    component={About}
                />
                </Drawer.Navigator>
        </NavigationContainer>
    );
}


export default function Main() {
  return (
    <PaperProvider theme={theme}>
        <AsyncStorageProvider>
            <App />
        </AsyncStorageProvider>
    </PaperProvider>
  );
}


AppRegistry.registerComponent("Particle AI", () => Main);
