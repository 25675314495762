import * as React from 'react';
import { Button, Card, Text } from 'react-native-paper';
import { TextInput } from 'react-native-paper';
import { Divider } from 'react-native-paper';
import { View } from 'react-native';
import { AsyncStorageContext } from './AsyncStorageContext';

const Advanced = ({ route }) => {
    const { storage, resetSettings, setItem } = React.useContext(AsyncStorageContext);

    const [prompt, setPrompt] = React.useState(storage['prompt']);
    const [temperature, setTemperature] = React.useState(storage['temperature']);
    const [matchThreshold, setMatchThreshold] = React.useState(storage['matchThreshold']);
    const [matchCount, setMatchCount] = React.useState(storage['matchCount']);
    const [minContentLength, setMinContentLength] = React.useState(storage['minContentLength']);

    const theme = route.params.theme;

    React.useEffect(() => {
        setItem('prompt', prompt);
    }, [prompt]);

    React.useEffect(() => {
        setItem('temperature', temperature);
    }, [temperature]);

    React.useEffect(() => {
        setItem('matchThreshold', matchThreshold);
    }, [matchThreshold]);

    React.useEffect(() => {
        setItem('matchCount', matchCount);
    }, [matchCount]);

    React.useEffect(() => {
        setItem('minContentLength', minContentLength);
    }, [minContentLength]);

    return (
        <React.Fragment>
            <View style={{flex: 1, backgroundColor: theme.colors.background}}>
                <Card style={{ marginTop: 30, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Card.Title title="Change the default sparkbot-search settings"/>
                    <Card.Content>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text variant="titleLarge">Prompt</Text>
                        <TextInput value={prompt} onChangeText={setPrompt} multiline={true}/>

                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/> 
                        <Text variant="titleLarge">Temperature</Text>
                        <TextInput  value={temperature} onChangeText={setTemperature}/>

                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text variant="titleLarge">Match Threshold</Text>
                        <TextInput  value={matchThreshold} onChangeText={setMatchThreshold}/>

                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text variant="titleLarge">Match Count</Text>
                        <TextInput  value={matchCount} onChangeText={setMatchCount}/>

                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text variant="titleLarge">Min Content Length</Text>
                        <TextInput  value={minContentLength} onChangeText={setMinContentLength}/>

                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Button onPress={() => resetSettings()}>Reset to defaults</Button>
                    </Card.Content>
                </Card>
            </View>

        </React.Fragment>
    );
};

export default Advanced;
