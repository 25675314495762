import * as React from 'react';
import { Button, Card, Text } from 'react-native-paper';
import { TextInput, ActivityIndicator } from 'react-native-paper';
import { Divider } from 'react-native-paper';
import { ScrollView } from 'react-native';
import Markdown from 'react-native-markdown-display';
import { getEdgeFunctionUrl, onLinkPress, markdownStyles, supabaseAnonKey } from './common'

const About = ({ route }) => {
    const theme = route.params.theme;
    const [assets, setAssets] = React.useState<string>('');
    const [loading, setLoading] = React.useState(true)

    const requestOptions = {
        method: 'GET',
        headers: {
            apikey: supabaseAnonKey,
            Authorization: `Bearer ${supabaseAnonKey}`,
            'Content-Type': 'application/json',
        }
    };

    //fetch the about.md file from supabase storage using 'fetch'
    fetch('https://txozrvovcnvzlijumbjy.supabase.co/storage/v1/object/public/about/aboutMD.md', requestOptions)
        .then((response) => response.text())
        .then((response) => {
            setAssets(response)
            setLoading(false)
    })
    .catch((error) => {
        console.error('get aboutMD.md ERROR: ', error);
    });

    return (
        <React.Fragment>
            <ScrollView style={{flex: 1, backgroundColor: theme.colors.background}}>
                {loading && <ActivityIndicator style={{ marginTop: 30 }} animating={true}/>}
                <Card style={{ marginTop: 30, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Markdown style={markdownStyles}>{assets}</Markdown>
                    <Card.Content>
                    </Card.Content>
                </Card>
            </ScrollView>

        </React.Fragment>
    );
};

export default About;
