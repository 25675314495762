import * as React from 'react';
import { Button, Card, Text, RadioButton } from 'react-native-paper';
import { TextInput } from 'react-native-paper';
import { Divider } from 'react-native-paper';
import { View } from 'react-native';
import LogRocket from 'logrocket';
import { AsyncStorageContext } from './AsyncStorageContext';

const Profile = ({ route }) => {
    const {storage, resetSettings, setItem } = React.useContext(AsyncStorageContext);
    const [username, setUserName] = React.useState(storage['username']);
    const [advancedMode, setAdvancedMode] = React.useState(storage['advancedMode']);

    const theme = route.params.theme;

    React.useEffect(() => {
        setItem('username', username);
        LogRocket.identify(username)
    }, [username]);

    React.useEffect(() => {
        console.log('storage advancedMode', advancedMode)
        setItem('advancedMode', advancedMode );
    }, [advancedMode]);

    return (
        <React.Fragment>
            <View style={{flex: 1, backgroundColor: theme.colors.background}}>
                <Card style={{ marginTop: 30, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Card.Title title="You can optionally enter a name or email here to tag your reactions!" />
                    <Card.Content>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 10 }}/>
                        <TextInput  value={username} onChangeText={setUserName}/>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 10 }}/>
                        <Text style={{ marginTop: 30 }}>Advanced Mode Toggle</Text>
                        <RadioButton value="Advanced Mode" status={advancedMode === 'true' ? 'checked' : 'unchecked'} onPress={() => setAdvancedMode(advancedMode === 'true' ? 'false' : 'true')}/>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Button onPress={() => resetSettings()}>Reset to defaults</Button>
                    </Card.Content>
                </Card>
            </View>

        </React.Fragment>
    );
};

export default Profile;
