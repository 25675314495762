export interface Embedding {
    id: number | null;
    page: string;
    content: string;
    token_count: number;
    slug: string;
    heading: string;
}

export const EmptyEmbedding: Embedding = {content: ''};
