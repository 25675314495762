import React, { createContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

//Prompt
const defaultPrompt = 
" You are a very enthusiastic Particle IoT chat bot called Sparkbot who loves\
 to help people! Given the following sections from the Particle IoT\
 documentation, try to answer the question using only that information,\
 outputted in markdown format. If you are unsure and the answer\
 is not explicitly written in the documentation and not known to you otherwise, say\
 Sorry, I don't know how to help with that.";

//temperature
const defaultTemperature = '0';

//Match threshold
const defaultMatchThreshold = '0.78';

//Match count
const defaultMatchCount = '10';

//Content length
const defaultMinContentLength = '50';


interface Storage {
    [key: string]: string;
};

const AsyncStorageContext = createContext({
    storage: {},
    setItem: async (key: string, value: string) => {},
    removeItem: async (key: string) => {},
    getItem: async (key: string): Promise<string> => {return ''},
    resetSettings: async () => {}
});


class AsyncStorageProvider extends React.Component<{}, { storage: Storage }> {

  //table of defaults
  settingDefaults = {
    prompt: defaultPrompt,
    temperature: defaultTemperature,
    matchThreshold: defaultMatchThreshold,
    matchCount: defaultMatchCount,
    minContentLength: defaultMinContentLength,
    advancedMode: 'false',
    username: ''
  }

  constructor(props) {
    super(props);

    this.state = {
      storage: {},
    };
  }

  async componentDidMount() {

    console.log('componentDidMount')

    await this.setupDefaults();
  }

  setupDefaults = async () => {
    //initial state
    let initialStorage = {}

    //read all the default strings from settingDefaults
    //if they are not in storage, add them
    //if they are in storage, use them
    for (const key in this.settingDefaults) {
        //store in state
        initialStorage[key] = await this.getItemInternal(key)
    }

    this.setState({ storage: initialStorage });
  }

  
  getItemInternal = async (key: string): string => {
    //console.log('getItemInternal', key)

    let value = undefined

    //if the local state doesn't have it, get from the store
    if (this.state.storage[key] === undefined) {
        //console.log('getItemInternal: getting from store', key)
        value = await AsyncStorage.getItem(key);

        //if it's not in the store, use the default
        if (value === null) {
            console.log('getItemInternal: getting from default', key, this.settingDefaults[key])
            value = this.settingDefaults[key]
        }
        else {
            console.log('getItemInternal: got from store', key, value)
        }
    }
    else {
        console.log('getItemInternal: getting from state', key, this.state.storage[key])
        value = this.state.storage[key]
    }

    return value
  }

  setItem = async (key: string, value: string) => {
    console.log('setItem', key, value)
    await AsyncStorage.setItem(key, value);
    this.setState({ storage: { ...this.state.storage, [key]: value } });
  };

  getItem = async (key: string): Promise<string> => {
    console.log('getItem', key)
    //return a promise closure
    return this.getItemInternal(key)
  };

  removeItem = async (key: string) => {
    await AsyncStorage.removeItem(key);
    const newStorage = { ...this.state.storage };
    delete newStorage[key];
    this.setState({ storage: newStorage });
  };

  resetSettings = async () => {
    console.log('resetSettings')
    await AsyncStorage.clear()

    this.setState({ storage: {} });

    //reinit with defaults
    await this.setupDefaults();
  }

  render() {
    const { children } = this.props;
    const { storage } = this.state;
    return (
      <AsyncStorageContext.Provider
        value={{ storage, setItem: this.setItem, removeItem: this.removeItem, getItem: this.getItem, resetSettings: this.resetSettings }}
      >
        {children}
      </AsyncStorageContext.Provider>
    );
  }
}

export { AsyncStorageContext, AsyncStorageProvider };
