import * as React from 'react';
import { Avatar, Button, Card, Text, Divider } from 'react-native-paper';
import { Searchbar } from 'react-native-paper';
import { ActivityIndicator } from 'react-native-paper';
import { View, ScrollView, Pressable } from 'react-native';
import Ionicons from "@expo/vector-icons/Ionicons";
import Markdown from 'react-native-markdown-display';
import * as Clipboard from 'expo-clipboard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import { getEdgeFunctionUrl, onLinkPress, markdownStyles, supabaseAnonKey } from './common'
import type {CreateCompletionResponse} from 'openai'
import { Dialog, Portal } from 'react-native-paper';
import { SSE } from 'sse.js';
import { Chat } from './chatTypes';
import { AsyncStorageContext } from './AsyncStorageContext';

const defaultPrompt = ""

const LeftContent = props => <Avatar.Icon {...props} icon="book" />

function promptDataReducer(
    state: any[],
    action: {
      index?: number
      answer?: string | undefined
      status?: string
      query?: string | undefined
      type?: 'remove-last-item' | string
    }
  ) {
    // set a standard state to use later
    let current = [...state]

    //console.log('promptDataReducer', action.index )
  
    if (action.type) {
      switch (action.type) {
        case 'remove-last-item':
          current.pop()
          return [...current]
        default:
          break
      }
    }
  
    // check that an index is present
    if (action.index === undefined) return [...state]
  
    if (!current[action.index]) {
      current[action.index] = { query: '', answer: '', status: '' }
    }
  
    current[action.index].answer = (action.answer ?? '').trim()
  
    if (action.query) {
      current[action.index].query = action.query
    }
    if (action.status) {
      current[action.index].status = action.status
    }
  
    //console.log('current reducer', ...current)

    return [...current]
}


const presetButtons = [
    "What does it mean when the red light is blinking?",
    "How can I check if my device is connected to the cellular network from the firmware?",
    "What do the m.8 wiring colors mean on the tracker one?",
    "How many devices can I have on my free plan?"
];


const AISearch = ({ route }) => {
    const { storage } = React.useContext(AsyncStorageContext);

    const [text, setText] = React.useState(defaultPrompt);
    const [waiting, setWaiting] = React.useState(false)
    const [showFeeback, setShowFeeback] = React.useState(false)
    const [answer, setAnswer] = React.useState<string>('')
    const eventSourceRef = React.useRef<SSE>()
    const [userName, setUserName] = React.useState('')

    const [promptIndex, setPromptIndex] = React.useState(0)
    const [promptData, dispatchPromptData] = React.useReducer(promptDataReducer, [])

    const [visible, setVisible] = React.useState(false);
    const showDialog = () => setVisible(true);

    const theme = route.params.theme;

    React.useEffect(() => {
        AsyncStorage.getItem('firstTime').then((value) => {
            console.log('firstTime', value)
            if (!value) {
                setVisible(true);
            }
        }).catch((error) => {
            console.log(error)
            setVisible(true);
        });
    
    }, []);

    function hideDialog() {
        AsyncStorage.setItem('firstTime', 'true');
        setVisible(false);
    }
  
    useFocusEffect(
        React.useCallback(() => {    
            AsyncStorage.getItem('username').then((value) => {
                if (value) {
                    setUserName(value);
                }
            });
        }, [])
    );

    const handleSearch = React.useCallback(
        async (query: string) => {

            const edgeFunctionUrl = getEdgeFunctionUrl()

            if (!edgeFunctionUrl) {
                return console.error('No edge function url')
            }

            console.log("handleSearch", query)
        
            setAnswer('')
            dispatchPromptData({ index: promptIndex, answer: undefined, query })
            setWaiting(true)
            setShowFeeback(false)

            if(1) {
                const eventSource = new SSE(`${edgeFunctionUrl}/sparkbot-search`, {
                    headers: {
                        apikey: supabaseAnonKey,
                        Authorization: `Bearer ${supabaseAnonKey}`,
                        'Content-Type': 'application/json',
                    },
                    payload: JSON.stringify({   query,
                                                context: promptData,
                                                prompt: storage['prompt'],
                                                temperature: storage['temperature'],
                                                matchThreshold: storage['matchThreshold'],
                                                matchCount: storage['matchCount'],
                                                minContentLength: storage['minContentLength'] }),
                })
            
                function handleError<T>(err: T) {
                    setWaiting(false)
                    console.error(err)
                }
            
                eventSource.addEventListener('error', handleError)
                eventSource.addEventListener('message', (e: any) => {
                    try {
                    if (e.data === '[DONE]') {
                        setWaiting(false)
                        setShowFeeback(true)
                        setPromptIndex((x) => {
                        return x + 1
                        })

                        //store the prompt data unless the query is one of presetButtons
                        if (!presetButtons.includes(query)) {
                            storeResult(null, text, answer)
                        }

                        return
                    }

                    setWaiting(true)

                    const completionResponse: CreateCompletionResponse = JSON.parse(e.data)

                    for (let i = 0; i < completionResponse.choices.length; i++) {
                        const element = completionResponse.choices[i];
                        //console.log('element', element)
                        const text = element.delta.content

                        if (text?.length > 0) {
                            setAnswer((answer) => {
                                const currentAnswer = answer ?? ''

                                //console.log('currentAnswer', currentAnswer)

                                dispatchPromptData({
                                    index: promptIndex,
                                    answer: (currentAnswer ?? '') + text
                                })

                                //console.log('new Answer', (answer ?? '') + text)

                                return (answer ?? '') + text
                            })
                        }
                    }

                    } catch (err) {
                        handleError(err)
                    }
                })
            
                eventSource.stream()
            
                eventSourceRef.current = eventSource
            }
            else {
                const response = await fetch('${edgeFunctionUrl}/sparkbot-search');
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
          
                // Access response headers
                console.log('Response headers:', response.headers);
          
                // Read the stream
                const readStream = async () => {
                  try {
                    const { done, value } = await reader.read();
                    if (done) {
                      console.log('Stream complete');
                      return;
                    }
          
                    // Parse the Server-Sent Events manually
                    const chunk = decoder.decode(value);
                    console.log('Received chunk:', chunk);
          
                    // Continue reading the stream
                    readStream();
                  } catch (error) {
                    console.error('Error while reading the stream:', error);
                  }
                };
          
                // Start reading the stream
                readStream();
            }
        },
        [promptIndex, promptData]
    )

    function handlePresetButtonPress(query) {
        setText(query)
        handleSearch(query)
    }

    function searchProducts(e) {
        //console.log('searchProducts', text)
        if (e.nativeEvent.key == "Enter"){
            handleSearch(text)
        }
    }

    function storeResult(approval: number | null, query: string, answer: string) {

        console.log(`storeResult: ${approval}, ${query}, ${answer}`)

        //check the answer doesn't start with a bad response
        if (answer?.startsWith('Sorry, I don\'t know how to help with that.')) {
            console.log('bad answer, not storing')
            return
        }

        const edgeFunctionUrl = getEdgeFunctionUrl()

        const chatEntry: Chat = { id: null, created_at: null, query, answer, approval, user: userName, build: '2' };

        const requestOptions = {
            method: 'POST',
            headers: {
                apikey: supabaseAnonKey,
                Authorization: `Bearer ${supabaseAnonKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(chatEntry)
        };

        console.log('requestOptions', requestOptions)

        fetch(`${edgeFunctionUrl}/submit-results`, requestOptions)
            .then((response) => response.json())
            .then((error) => {
                console.log('submit-results: ', error)
            })
            .catch((error) => {
                console.error('submit-results ERROR: ', error);
            });

        if (approval !== null) {
            setShowFeeback(false)
        }   
    }

    function pressedThumbsUp() {
        console.log('pressedThumbsUp')
        storeResult(1, text, answer)
    }

    function pressedWishyWashy() {
        console.log('pressedWishyWashy')
        storeResult(0, text, answer)
    }

    function pressedThumbsDown() {
        console.log('pressedThumbsDown')
        storeResult(-1, text, answer)
    }

    async function copyToClipboard(item) {
        await Clipboard.setStringAsync(item)
        console.log('copied to clipboard', item)
    }

    return (
        <React.Fragment>
            <ScrollView style={{flex: 1, backgroundColor: theme.colors.background}}>
                <Card style={{ marginTop: 30, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Card.Content >
                        <Text style={{ marginTop: 30 }}>Hello there! I'm Sparkbot AI, Particle's IoT Support Assistant and I'm an expert on all things in the Particle platform. What questions can I help you with today?</Text>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Searchbar
                                style={{ height: 60 }}
                                value={text}
                                onChangeText={text => setText(text)}
                                onKeyPress={searchProducts}/>
                        { answer && <Markdown onLinkPress={onLinkPress} style={markdownStyles}>{answer}</Markdown> }
                        { waiting && <ActivityIndicator style={{ marginTop: 30 }} animating={true} /> }
                        { showFeeback &&
                            <React.Fragment>
                                <Text style={{ marginTop: 30 }}>Was this helpful?</Text>
                                <View
                                    style={[
                                        {
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            columnGap: 20,
                                            marginTop: 30,
                                        },
                                    ]}>
                                    <Pressable onPressIn={() => pressedThumbsUp()}>
                                        <Ionicons
                                            name={"thumbs-up"}
                                            size={48}
                                            color={'#3AD599'}
                                        />
                                    </Pressable>
                                    <Pressable onPressIn={() => pressedWishyWashy()}>
                                        <Ionicons
                                            name={"code-working-outline"}
                                            size={48}
                                            color={'#FFBC80'}
                                        />
                                    </Pressable>
                                    <Pressable onPressIn={() => pressedThumbsDown()}>
                                        <Ionicons
                                            name={"thumbs-down"}
                                            size={48}
                                            color={'#F45151'}
                                        />
                                    </Pressable>
                                </View>
                            </React.Fragment>
                        }
                    </Card.Content>
                    <Card.Actions>
                        { answer &&
                            <Button disabled={waiting} onPress={() => copyToClipboard(answer)}>Copy Reponse To Clipboard</Button>
                        }
                    </Card.Actions>
                </Card>
                <Card style={{ marginTop: 30, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Card.Title title="Try out these example queries"/>
                    <Card.Content>
                        {presetButtons.map((b, idx) => (
                            <Button disabled={waiting} onPress={() => handlePresetButtonPress(b)}>{b}</Button>
                        ))}
                    </Card.Content>
                </Card>
                <Portal>
                    <Dialog visible={visible} onDismiss={hideDialog} style={{ alignSelf: 'center', width: 400, height: 300 }}>
                        <Dialog.Title>Welcome New User!</Dialog.Title>
                        <Dialog.Content>
                            <Text variant="bodyMedium">Please take a moment to go into the profile menu and enter your user name to help out with tagging / logging the queries! You can view all submitted questions in the history section. Enjoy!</Text>
                        </Dialog.Content>
                        <Dialog.Actions>
                            <Button style={{ alignSelf: 'center' }} onPress={hideDialog}>
                                <Text>OK!</Text>
                            </Button>
                        </Dialog.Actions>
                    </Dialog>
                </Portal>
            </ScrollView>
        </React.Fragment>
    );
};

export default AISearch;
