export interface Chat {
    id: number | null;
    created_at: string | null;
    query: string;
    answer: string;
    approval: number | null;
    user: string;
    build: string;
}

export const EmptyChat: Chat = {answer: '', query: '', approval: 0, build: '2'};
