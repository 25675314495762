import { StyleSheet } from 'react-native';
import { Linking } from 'react-native';
import { DefaultTheme, MD3DarkTheme as DarkTheme } from 'react-native-paper';

export const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InR4b3pydm92Y252emxpanVtYmp5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEwMjY5NjQsImV4cCI6MTk5NjYwMjk2NH0.XyyTAW1Zlmv0DC2vcAfYWeqx120mKMNxHkh8QixHstA"
export const supabaseUrl = "https://txozrvovcnvzlijumbjy.supabase.co"


const themeLight = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
      ...DefaultTheme.colors,
      primary: '#00F0F0',
      accent: '#FF0000',
      background: '#FFFFFF',
      surface: '#E2E4EB',
      onSurface: '#0000FF', //main text font
      backdrop: '#00FF00',
      text: '#F0000F',
    },
};

const themeDark = {
    ...DarkTheme,
    dark: true,
    roundness: 2,
    colors: {
      ...DarkTheme.colors,
      primary: '#BBBDC4', //the links
      accent: '#000000', //??
      background: '#010D14', //main background
      surface: '#01131D', //card surface
      onSurface: '#F5F6FA', //main text font
      backdrop: '#00FF00',
      text: '#F0000F',
    },
};

export const theme = themeDark;

export const markdownStyles = StyleSheet.create({
    body: {
        marginTop: 10,
        marginBottom: 10,
        lineHeight: 30,
        fontFamily: 'Sans-serif',
        color: theme.colors.onSurface,
    },
    heading1: {
      fontSize: 32,
    },
    heading2: {
      fontSize: 24,
    },
    heading3: {
      fontSize: 18,
    },
    heading4: {
      fontSize: 16,
    },
    heading5: {
      fontSize: 13,
    },
    heading6: {
      fontSize: 11,
    },
    code_block: {
        borderWidth: 1,
        //borderColor: '#AFE4EE',
        backgroundColor: theme.colors.background,
        padding: 2,
        borderRadius: 1,
    },
    blockquote: {
        backgroundColor: theme.colors.background,
        //borderColor: '#CFECCC',
        borderLeftWidth: 4,
        marginLeft: 5,
        paddingHorizontal: 5,
    },
    fence: {
        backgroundColor: theme.colors.background,
        //borderColor: '#CFECCC',
        borderLeftWidth: 2,
        marginLeft: 2,
        paddingHorizontal: 5,
    },
    code_inline: {
        borderWidth: 1,
        //borderColor: '#CFECCC',
        backgroundColor: theme.colors.background,
        padding: 2,
        borderRadius: 1,
    },
  });


export function onLinkPress(url) {
    console.log('onLinkPress', url)
    if (url) {
        //if the url starts with https call it directly
        if (url.startsWith('https://')) {
            Linking.openURL(url)
        }
        else {
            Linking.openURL('https://docs.particle.io' + url)
        }
        return false;
      }
      
      // return true to open with `Linking.openURL
      // return false to handle it yourself
      return true
}


export function getEdgeFunctionUrl() {
    const isPlatform = supabaseUrl.match(/(supabase\.co)|(supabase\.in)/)
  
    if (isPlatform) {
      const [schemeAndProjectId, domain, tld] = supabaseUrl.split('.')
      return `${schemeAndProjectId}.functions.${domain}.${tld}`
    } else {
      return `${supabaseUrl}/functions/v1`
    }
}

