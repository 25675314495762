import * as React from 'react';
import { Card, Text } from 'react-native-paper';
import { Divider } from 'react-native-paper';
import { ScrollView } from 'react-native';
import Ionicons from "@expo/vector-icons/Ionicons";
import Markdown from 'react-native-markdown-display';
import { useFocusEffect } from '@react-navigation/native';
import { DataTable, Button } from 'react-native-paper';
import { getEdgeFunctionUrl, onLinkPress, markdownStyles, supabaseAnonKey } from './common'
import { Chat, EmptyChat } from './chatTypes';
import { ActivityIndicator } from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';

const History = ({ route }) => {
    const [loading, setLoading] = React.useState(true)
    const [page, setPage] = React.useState<number>(0);
    const [chat, setChat] = React.useState<Chat[]>([]);
    const [clicked, setClicked] = React.useState<Chat>(EmptyChat);

    const number_items_per_page = 10;
    const from = page * number_items_per_page;
    const to = Math.min((page + 1) * number_items_per_page, chat.length);

    const theme = route.params.theme;

    const [sort, setSort] = React.useState<string>('date'); //query answer approval date
    const [sortOrder, setSortOrder] = React.useState<string>('descending'); //ascending descending

    function sortChats(chats) {
        if (sort === 'query') {
            if (sortOrder === 'ascending') {
                chats.sort((a, b) => a.query.localeCompare(b.query))
            } else {
                chats.sort((a, b) => b.query.localeCompare(a.query))
            }
        } else if (sort === 'answer') {
            if (sortOrder === 'ascending') {
                chats.sort((a, b) => a.answer.localeCompare(b.answer))
            } else {
                chats.sort((a, b) => b.answer.localeCompare(a.answer))
            }
        } else if (sort === 'approval') {
            if (sortOrder === 'ascending') {
                chats.sort((a, b) => a.approval - b.approval)
            } else {
                chats.sort((a, b) => b.approval - a.approval)
            }
        } else if (sort === 'date') {
            if (sortOrder === 'ascending') {
                chats.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
            } else {
                chats.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            }
        }

        return chats
    }

    useFocusEffect(
        React.useCallback(() => {    
            const edgeFunctionUrl = getEdgeFunctionUrl()

            const requestOptions = {
                method: 'POST',
                headers: {
                    apikey: supabaseAnonKey,
                    Authorization: `Bearer ${supabaseAnonKey}`
                }
            };

            fetch(`${edgeFunctionUrl}/get-results`, requestOptions)
                .then((response) => response.json())
                .then((results) => {
                    console.log('get-results: ', results)

                    var chats: Chat[] = []

                    for (let i = 0; i < results.results.length; i++) {
                        chats.push({
                            id: results.results[i].id,
                            created_at: results.results[i].created_at,
                            query: results.results[i].query,
                            answer: results.results[i].answer,
                            approval: results.results[i].approval,
                            user: results.results[i].user,
                            build: results.results[i].build
                        })
                    }

                    console.log('chats', chats)
                    
                    setChat(chats)
                    setLoading(false)
                })
                .catch((error) => {
                    console.error('get-results ERROR: ', error);
                });

        }, [])
    );

    React.useEffect(() => {
        setClicked(EmptyChat);
    }, [page])

    async function copyToClipboard(item) {
        await Clipboard.setStringAsync(item)
        console.log('copied to clipboard', item)
    }

    function clickSort(newSort: string) {
        console.log('clickSort', newSort, sortOrder)
        if (sort === newSort) {
            if (sortOrder === 'ascending') {
                setSortOrder('descending')
            } else {
                setSortOrder('ascending')
            }
        } else {
            setSort(newSort)
            setSortOrder('ascending')
        }
        setPage(0)
    }

    const chatsSorted = sortChats(chat)

    console.log('render')
    chatsSorted.slice(from, to).map((c: Chat, idx) => (
        console.log('c', c)
    ))
    console.log('/render')

    return (
        <React.Fragment>
            <ScrollView style={{flex: 1, backgroundColor: theme.colors.background}}>
                {loading && <ActivityIndicator style={{ marginTop: 30 }} animating={true}/>}
                <Card style={{ marginTop: 15, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Card.Title title="Sparkbot AI - History" />
                    <Card.Content>
                        <DataTable>
                            <DataTable.Header>
                                <DataTable.Title onPress={() => clickSort('query')}>Query</DataTable.Title>
                                <DataTable.Title onPress={() => clickSort('answer')}>Answer</DataTable.Title>
                                <DataTable.Title onPress={() => clickSort('approval')} numeric>Rating</DataTable.Title>
                            </DataTable.Header>

                            <DataTable.Pagination
                                page={page}
                                numberOfPages={Math.ceil(chat.length / number_items_per_page)}
                                showFastPaginationControls={false}
                                label={`${from + 1}-${to} of ${chat.length}`}
                                onPageChange={(page) => setPage(page)}
                            />

                            {chatsSorted.slice(from, to).map((c: Chat, idx) => (
                                <DataTable.Row key={idx} onPress={() => setClicked(c)}>
                                    <DataTable.Cell>{c.query}</DataTable.Cell>
                                    <DataTable.Cell>{c.answer}</DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        { c.approval < 0 && 
                                        <Ionicons
                                            name={"thumbs-down"}
                                            size={32}
                                            color={'#F45151'}
                                        /> }
                                        { c.approval == 0 &&
                                            <Ionicons
                                                name={"code-working-outline"}
                                                size={32}
                                                color={'#FFBC80'}
                                            />
                                        }
                                        { c.approval > 0 && 
                                        <Ionicons
                                            name={"thumbs-up"}
                                            size={32}
                                            color={'#3AD599'}
                                        /> }
                                    </DataTable.Cell>
                                </DataTable.Row>
                            ))}
                        </DataTable>
                    </Card.Content>
                </Card>

                <Divider style={{ height: 0, marginTop: 0, marginBottom: 0 }}/>

                <Card style={{ marginTop: 0, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Card.Content>
                        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{clicked.query}</Text>
                        <Markdown onLinkPress={onLinkPress} style={markdownStyles}>{clicked.answer}</Markdown>
                    </Card.Content>
                </Card>

                <Card.Actions>
                        { clicked.answer &&
                            <Button onPress={() => copyToClipboard(clicked.answer)}>Copy Reponse To Clipboard</Button>
                        }
                </Card.Actions>
            </ScrollView>

        </React.Fragment>
    );
};

export default History;
