import * as React from 'react';
import { Card, Text, Searchbar } from 'react-native-paper';
import { Divider } from 'react-native-paper';
import { ScrollView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { DataTable, Button } from 'react-native-paper';
import { getEdgeFunctionUrl, onLinkPress, markdownStyles, supabaseAnonKey } from './common'
import { Embedding, EmptyEmbedding } from './embeddingTypes';
import { ActivityIndicator } from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';

//TODO - add in the search distance setting

const Embeddings = ({ route }) => {
    const [searchText, setSearchText] = React.useState('');
    const [searchDistance, setSearchDistance] = React.useState('0.78');
    const [searchCount, setSearchCount] = React.useState('10');
    const [loading, setLoading] = React.useState(true)
    const [page, setPage] = React.useState<number>(0);
    const [embeddings, setEmbeddings] = React.useState<Embedding[]>([]);
    const [clicked, setClicked] = React.useState<Embedding>(EmptyEmbedding);

    const number_items_per_page = 10;
    const from = page * number_items_per_page;
    const to = Math.min((page + 1) * number_items_per_page, embeddings.length);

    const theme = route.params.theme;

    const [sortOrder, setSortOrder] = React.useState<string>('descending'); //ascending descending

    function sortEmbeddings(embeddings : Embedding[]) {
        /*
        if (sortOrder === 'ascending') {
            embeddings.sort((a, b) => a.heading.localeCompare(b.heading))
        } else {
            embeddings.sort((a, b) => b.heading.localeCompare(a.heading))
        }
        */

        return embeddings
    }

    const handleSearch = React.useCallback(
        async (searchText: string, searchDistance: string) => {

            const edgeFunctionUrl = getEdgeFunctionUrl()

            const requestOptions = {
                method: 'POST',
                headers: {
                    apikey: supabaseAnonKey,
                    Authorization: `Bearer ${supabaseAnonKey}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    searchText: searchText,
                    searchDistance: searchDistance,
                    searchCount: searchCount
                })
            };

            fetch(`${edgeFunctionUrl}/get-embeddings`, requestOptions)
                .then((response) => response.json())
                .then((responseJSON) => {
                    console.log('get-embeddings: ', responseJSON)

                    setEmbeddings(responseJSON.embeddings)
                    setLoading(false)
                })
                .catch((error) => {
                    console.error('get-results ERROR: ', error);
                });
        }, [embeddings]
    )

    useFocusEffect(
        React.useCallback(() => {
            handleSearch(searchText, searchDistance)
        }, [])
    );

    React.useEffect(() => {
        setClicked(EmptyEmbedding);
    }, [page])

    async function copyToClipboard(item) {
        await Clipboard.setStringAsync(item)
        console.log('copied to clipboard', item)
    }

    function clickSort() {
        if (sortOrder === 'ascending') {
            setSortOrder('descending')
        } else {
            setSortOrder('ascending')
        }
        setPage(0)
    }

    function searchProducts(e) {
        console.log('searchProducts')
        if (e.nativeEvent.key == "Enter"){
            //update the search
            handleSearch(searchText, searchDistance)
        }
    }

    const embeddingsSorted = sortEmbeddings(embeddings)

    console.log('render')
    embeddingsSorted.slice(from, to).map((c: Embedding, idx) => (
        console.log('c', c)
    ))
    console.log('/render')

    return (
        <React.Fragment>
            <ScrollView style={{flex: 1, backgroundColor: theme.colors.background}}>
                {loading && <ActivityIndicator style={{ marginTop: 30 }} animating={true}/>}
                <Card style={{ marginTop: 15, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Card.Title title="Sparkbot Embeddings" />
                    <Card.Content>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text style={{ marginBottom: 30 }}>Embedding Search Phrase</Text>
                        <Searchbar
                                style={{ height: 60 }}
                                value={searchText}
                                onChangeText={searchText => setSearchText(searchText)}
                                onKeyPress={searchProducts}/>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text style={{ marginBottom: 30 }}>Search Distance</Text>
                        <Searchbar
                                style={{ height: 60 }}
                                value={searchDistance}
                                onChangeText={searchDistance => setSearchDistance(searchDistance)}
                                onKeyPress={searchProducts}/>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text style={{ marginBottom: 30 }}>Search Count</Text>
                        <Searchbar
                                style={{ height: 60 }}
                                value={searchCount}
                                onChangeText={searchCount => setSearchCount(searchCount)}
                                onKeyPress={searchProducts}/>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>

                        <DataTable>
                            <DataTable.Header>
                                <DataTable.Title onPress={() => clickSort()}>Heading</DataTable.Title>
                                <DataTable.Title>Page</DataTable.Title>
                                <DataTable.Title>Token Count</DataTable.Title>
                                <DataTable.Title>Slug</DataTable.Title>
                                <DataTable.Title>Content</DataTable.Title>
                            </DataTable.Header>

                            <DataTable.Pagination
                                page={page}
                                numberOfPages={Math.ceil(embeddings.length / number_items_per_page)}
                                showFastPaginationControls={false}
                                label={`${from + 1}-${to} of ${embeddings.length}`}
                                onPageChange={(page) => setPage(page)}
                            />

                            {embeddingsSorted.slice(from, to).map((c: Embedding, idx) => (
                                <DataTable.Row key={idx} onPress={() => setClicked(c)}>
                                    <DataTable.Cell>{c.heading}</DataTable.Cell>
                                    <DataTable.Cell>{c.page}</DataTable.Cell>
                                    <DataTable.Cell>{c.token_count}</DataTable.Cell>
                                    <DataTable.Cell>{c.slug}</DataTable.Cell>
                                    <DataTable.Cell>{c.content}</DataTable.Cell>
                                </DataTable.Row>
                            ))}
                        </DataTable>
                    </Card.Content>
                </Card>

                <Divider style={{ height: 0, marginTop: 0, marginBottom: 0 }}/>

                <Card style={{ marginTop: 0, marginLeft: 30, marginRight: 30, backgroundColor: theme.colors.surface }}>
                    <Card.Content>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{clicked.heading}</Text>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text>{clicked.page}</Text>
                        <Divider style={{ height: 0, marginTop: 0, marginBottom: 30 }}/>
                        <Text>{clicked.content}</Text>
                    </Card.Content>
                </Card>

                <Card.Actions>
                        { clicked.heading &&
                            <Button onPress={() => copyToClipboard(clicked.heading)}>Copy Selected Embedding To Clipboard</Button>
                        }
                </Card.Actions>
            </ScrollView>

        </React.Fragment>
    );
};

export default Embeddings;
